<template>
  <b-card :title="title">
    <b-row class="my-2">
      <b-col cols="12">
        <b-button
          class="mr-50"
          :disabled="loading || !items.length"
          @click="generatePoliceReport"
        >
          Descarrega el fitxer de la policia
        </b-button>
        i puja'l a la
        <a href="https://hospederias.guardiacivil.es/" target="_blank">
          pàgina de la Guàrdia Civil
        </a>
      </b-col>
    </b-row>

    <police-reports-table
      :items="items"
      :loading="loading"
      @items-selected="onItemsSelected"
    />

    <b-row class="mt-2">
      <b-col cols="12" class="d-flex justify-content-end">
        <b-button
          :disabled="loading || !selectedItems.length"
          variant="primary"
          @click="updateBookingStats"
        >
          Marca com a enviat
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BButton } from "bootstrap-vue";
import PoliceReportsTable from "@/views/administration/police-reports/components/PoliceReportsTable.vue";
import { saveAs } from "file-saver";
import {
  formatDateObjectToDatabaseDate,
  formatDateObjectToHiTime,
  formatDateObjectToYmdDate,
} from "@/utils/formatters";
import {
  getAccommodationPoliceReportText,
  getGuestPoliceReportText,
  notifyError,
  notifySuccess,
} from "@/utils/methods";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    PoliceReportsTable,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedItems: [],
    };
  },
  computed: {
    loading() {
      return this.$store.getters["bookings/loading"];
    },
    policeReportText() {
      if (!this.items.length) return null;

      const reportableItems = this.items.filter((item) => {
        return (
          item.reportableGuests.length &&
          item.policeRegistrationNumber &&
          item.policeRegistrationName
        );
      });
      if (!reportableItems.length) return null;

      // Agrupació hotelera line
      const text = [
        `0|07661AA000|FORA-VILA SA POBLA S.L.|${formatDateObjectToYmdDate(
          new Date()
        )}|${formatDateObjectToHiTime(new Date())}|${reportableItems.length}`,
      ];

      // Create text for each booking with reportable guests
      reportableItems.forEach((item) => {
        text.push(this.getItemPoliceReportText(item));
      });

      return text.join("\n");
    },
  },
  methods: {
    onItemsSelected(items) {
      this.selectedItems = items;
    },
    getItemPoliceReportText(item) {
      if (!item?.reportableGuests.length) return null;

      const text = [];

      // Firs line, accommodation info
      text.push(
        getAccommodationPoliceReportText({
          policeRegistrationName: item.policeRegistrationName,
          policeRegistrationNumber: item.policeRegistrationNumber,
          guestsAmount: item.reportableGuests.length,
        })
      );

      // Rest of the lines, one per guest
      item.reportableGuests.forEach((guest) => {
        text.push(
          getGuestPoliceReportText({
            guest,
            checkin: formatDateObjectToYmdDate(new Date(item.checkin)),
          })
        );
      });

      return text.join("\n");
    },
    generatePoliceReport() {
      this.$store.dispatch("app/setLoading", true);
      const blob = new Blob([this.policeReportText], {
        type: "text/plain;charset=utf-8",
      });
      saveAs(blob, "07661AA000.001");
      this.$store.dispatch("app/setLoading", false);
    },
    updateBookingStats() {
      if (!this.selectedItems.length) return;

      this.$store.dispatch("app/setLoading", true);

      const promises = [];

      this.selectedItems.forEach((bookingStatsUuid) => {
        promises.push(
          this.$store.dispatch("booking/updateStats", {
            uuid: bookingStatsUuid,
            policeReportSent: true,
            policeReportSentDate: formatDateObjectToDatabaseDate(new Date()),
          })
        );
      });

      Promise.all(promises)
        .then(() => {
          notifySuccess(
            "Reserves actualitzades",
            "Les reserves seleccionades han estat marcades com a enviades a la Policia"
          );
          this.selectedItems = [];
          this.$emit("updated");
        })
        .catch((error) => notifyError("Error", error))
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>
