<template>
  <b-tabs no-fade>
    <b-tab active>
      <template #title>
        <feather-icon class="mb-25 mb-lg-none" icon="ClockIcon" />
        <span class="d-none d-lg-block">Pendents d'enviar</span>
      </template>
      <unsent-police-reports-list
        title="Informes policials pendents d'enviar"
        :items="parsedUnsentPoliceReports"
        @updated="onBookingsUpdated"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import UnsentPoliceReportsList from "@/views/administration/police-reports/components/UnsentPoliceReportsList.vue";
import { notifyError } from "@/utils/methods";

export default {
  components: {
    BTabs,
    BTab,
    UnsentPoliceReportsList,
  },
  data() {
    return {
      selectedItems: [],
    };
  },
  computed: {
    unsentPoliceReports() {
      return this.$store.getters["bookings/unsentPoliceReports"];
    },
    parsedUnsentPoliceReports() {
      if (!this.unsentPoliceReports.length) return [];
      return this.unsentPoliceReports
        .filter((booking) => {
          return (
            !booking.contract || !!booking.contract?.guestsRegistrationProvided
          );
        })
        .map((booking) => {
          return {
            uuid: booking.uuid || this.$t("No definit"),
            localizator: booking.localizator || this.$t("No definit"),
            source: booking.source || null,
            highlighted: booking.highlighted || false,
            client: booking.client?.fullName || this.$t("No definit"),
            clientUuid: booking.client?.uuid || null,
            accommodation: booking.accommodation?.name || this.$t("No definit"),
            accommodationUuid: booking.accommodation?.uuid || null,
            policeRegistrationNumber:
              booking.accommodation?.policeRegistrationNumber || null,
            policeRegistrationName:
              booking.accommodation?.policeRegistrationName || null,
            checkin: booking.checkin || this.$t("No definit"),
            checkout: booking.checkout || this.$t("No definit"),
            reportableGuests: booking.guests?.filter((g) => g.age > 14) || [],
            bookingStatsUuid: booking.stats?.uuid || null,
          };
        });
    },
  },
  created() {
    this.fetchUnsentPoliceReports();
  },
  methods: {
    fetchUnsentPoliceReports() {
      this.$store
        .dispatch("bookings/fetchBookingsWithUnsentPoliceReport")
        .catch(() => {
          notifyError(
            this.$t("errors.fetchBookings.title"),
            this.$t("errors.fetchBookings.description")
          );
        });
    },
    onBookingsUpdated() {
      this.fetchUnsentPoliceReports();
    },
  },
};
</script>
